import "core-js/modules/es.array.push.js";
import { activeFinanceOrder } from "@/api/api";
export default {
  name: "visitorList",
  data() {
    return {
      tableData: [],
      orderInfo: [],
      dialogFormVisible: false,
      pageInfo: {
        active_id: '',
        pageSize: 10,
        current: 1,
        total: 0
      }
    };
  },
  mounted() {
    let data = this.$root.useRoute.query;
    this.pageInfo.active_id = data.active_id;
    this.getList();
  },
  methods: {
    reset() {
      this.pageInfo.name = '';
      this.pageInfo.province = '';
      this.pageInfo.city = '';
    },
    search() {
      this.pageInfo.pageSize = 10;
      this.pageInfo.current = 1;
      this.getList();
    },
    getList() {
      activeFinanceOrder(this.pageInfo).then(res => {
        this.pageInfo.total = parseInt(res.data.total);
        this.tableData = res.data.records;
        this.orderInfo = res.data2;
      });
    },
    showEdit(row) {
      let data = '';
      if (row) {
        data = JSON.stringify(row);
      }
      this.$root.useRouter.push({
        path: "/admin/adminSchool/schoolEdit",
        query: {
          Info: data
        }
      });
    },
    handleSizeChange(e) {
      //修改每页显示条数
      this.pageInfo.pageSize = e;
      this.pageInfo.current = 1;
      this.getList();
    },
    handleCurrentChange(e) {
      this.pageInfo.current = e;
      this.getList();
    }
  }
};